@import "Colors.css";

.score-group {
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid var(--color-background);
}

.score-group-horizontal {
  flex-flow: row nowrap;
}

.score-group-vertical {
  flex-flow: column nowrap;
}
