ul {
  margin-bottom: 0;
}

li {
  padding: 3px 0;
}

.rules-panel {
  padding: 20px;
}

.rules-panel-section {
  padding-bottom: 20px;
  font-size: 14px;
}

.span-book-title {
  font-style: italic;
}

.span-bold {
  font-weight: bold;
}
