@import "Colors.css";

.letter-option {
  border: 2px solid var(--color-unselected-tile-border);
  border-radius: 6px;
  margin: 0 6px;

  cursor: pointer;

  background-color: var(--color-unselected-tile);
}
@media (max-width: 600px) {
  .letter-option {
    margin: 0 2px;
  }
}

.selected {
  background-color: var(--color-selected-tile);
  border-color: var(--color-selected-tile-border);
}

.unpickable {
  background-color: var(--color-unpickable-tile);
  border-color: var(--color-unpickable-tile-border);
}
