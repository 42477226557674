.note-panel {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 100%;
  font-size: 16px;

  text-align: center;
}
@media (max-width: 767px) {
  .note-panel {
    display: none;
  }
}
