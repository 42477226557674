.preview-cell-display {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  padding: 6px 0;
  margin-right: 14px;

  color: var(--color-preview-display-text);
}
@media (max-width: 600px) {
  .preview-cell-display {
    margin-right: 6px;
  }
}

.preview-fieldset {
  width: auto;
  height: auto;
  border: 1px dashed var(--color-preview-display-border);
  border-radius: 6px;
}
@media (max-width: 600px) {
  .preview-fieldset {
    padding: 5.6px 8px 10px;
  }
}

.preview-legend {
  font-size: 14px;
}

.preview-cell-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
