@import "Colors.css";

.date-panel {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  height: 48px;
  width: 100%;
  font-size: 20px;

  border-bottom: 1px dotted var(--color-app-border);
}
