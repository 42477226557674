.leaderboard-modal {
  position: fixed;
  background: var(--color-modal-background);
  border: 2px solid var(--color-app-border);
  border-radius: 6px;
  font-size: 14px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 440px;
  width: 300px;
}
@media (min-width: 768px) {
  .leaderboard-modal {
    display: none;
  }
}

.leaderboard-modal-header {
  padding-bottom: 10px;
  text-align: center;
  padding-bottom: 20px;
}

.leaderboard-modal-close {
  margin-top: 16px;
  font-size: 14px;
  padding: 8px 12px;
}

.leaderboard-modal-message {
  font-size: 12px;
  text-align: center;
  padding: 0 10px 10px;
}

.mobile-leaderboard {
  margin: 0 auto;
}
