.rules-modal {
  position: fixed;
  background: var(--color-modal-background);
  border: 2px solid var(--color-app-border);
  border-radius: 6px;
  font-size: 14px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 460px;
  width: 300px;
}
@media (min-width: 768px) and (max-width: 1049px) {
  .rules-modal {
    height: 800px;
    width: 600px;
  }
}
@media (min-width: 1050px) {
  .rules-modal {
    display: none;
  }
}

.rules-modal-section {
  font-size: 11px;
  padding: 4px 20px;
}
@media (min-width: 768px) and (max-width: 1049px) {
  .rules-modal-section {
    font-size: 20px;
    padding: 8px 52px;
  }
}

.rules-modal-bullet {
  padding: 1px 0;
}
@media (min-width: 768px) and (max-width: 1049px) {
  .rules-modal-bullet {
    padding: 2px 0;
  }
}

.rules-modal-close {
  margin-top: 10px;
  font-size: 14px;
  padding: 8px 12px;
}
@media (min-width: 768px) and (max-width: 1049px) {
  .rules-modal-close {
    margin-top: 14px;
    font-size: 20px;
    padding: 12px 16px;
  }
}