.grid {
  display: inline-block;

  border: 1px solid black;
}

.grid-row {
  display: flex;
  flex-flow: row nowrap;
}
