.preview-cell {
  border: 2px solid var(--color-preview-tile-border);
  border-radius: 6px;
  margin: 0 6px;

  font-size: 32px;

  cursor: wait;

  background-color: var(--color-preview-tile);
  color: var(--color-preview-tile-letter);
}
@media (max-width: 600px) {
  .preview-cell {
    font-size: 22px;
    margin: 0 2px;
  }
}
