@import "Colors.css";

.score {
  border: 1px solid var(--color-background);

  font-size: 20px;
}

@media (max-width: 767px) {
  .score {
    font-size: 16px;
  }
}

.total {
  border: 2px solid var(--color-background);

  font-size: 28px;
}

@media (max-width: 767px) {
  .total {
    font-size: 20px;
  }
}
