.profile-modal {
  position: fixed;
  background: var(--color-modal-background);
  border: 2px solid var(--color-app-border);
  border-radius: 6px;
  padding: 20px;
  font-size: 14px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 300px;
  width: 300px;
}

.profile-header {
  font-size: 28px;
  margin-bottom: 5px;
}

.profile-games {
  font-size: 14px;
  margin-bottom: 15px;
}

.profile-fieldset {
  width: 100px;
  border: 1px solid black;
  border-radius: 6px;
  margin-bottom: 20px;
}
.profile-fieldset-inner {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
}
.profile-legend {
  padding: 0 6px;
  border-radius: 4px;
  font-size: 16px;
  background-color: var(--color-modal-background);
}

.profile-stars {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  margin-bottom: 5px;
  width: 70%;
}

.profile-star-group {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.profile-star-div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 50%;
}

.profile-star {

}

.profile-star-gold {
  color: var(--color-accolade-gold);
}

.profile-star-silver {
  color: var(--color-accolade-silver);
}

.profile-star-bronze {
  color: var(--color-accolade-bronze);
}

.profile-star-count {
  text-align: center;
  width: 50%;
}

.profile-note {
  margin-bottom: 20px;
}
