@import "Colors.css";

a {
  text-decoration: none;
  color: var(--color-footer-link);
}

.footer {
  position: fixed;
  bottom: 0px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: flex-end;

  width: 100%;
}

.footer-main {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;

  height: 48px;
  width: 100%;

  background-color: var(--color-footer);
  border-top: 1px dotted var(--color-app-border);
}

.footer-text {
  padding: 0 15px;

  font-size: 14px;
}
@media (max-width: 767px) {
  .footer-text {
    font-size: 12px;
  }
}

.footer-tab-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 767px) {
  .footer-tab-bar {
    display: none;
  }
}

.footer-tab {
  background-color: var(--color-footer);
  border: 1px dotted var(--color-app-border);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 6px 15px;
  margin-bottom: -1px;
  margin-right: -1px;
  font-size: 14px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .footer-tab {
    display: none;
  }
}
