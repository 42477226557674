.feedback-modal {
  position: fixed;
  background: var(--color-modal-background);
  border: 2px solid var(--color-app-border);
  border-radius: 6px;
  padding: 20px;
  font-size: 14px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 480px;
  width: 560px;
}

.feedback-modal-header {
  padding-bottom: 10px;
  text-align: center;
  padding-bottom: 20px;
}

.input {
  font-size: 14px;
  border: none;
  border-bottom: 1px dashed black;
  padding-bottom: 6px;
  margin-left: 10px;
  margin-bottom: 12px;
  background-color: var(--color-modal-background);
  width: 200px;
  text-align: center;
}
.input:focus {
  outline: none;
}

.textarea {
  font-size: 14px;
  margin-top: 16px;
  width: 400px;
  height: 200px;
  resize: none;
  font-family: 'Rubik', sans-serif;
}

.feedback-modal-button {
  margin-top: 16px;
  font-size: 14px;
  padding: 8px 12px;
}
