.letter-option-display {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: center;
}

.letter-option-div {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  margin-bottom: 17px;
}
