.hold-cell-display {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  padding: 6px 0;
  margin-left: 14px;
}
@media (max-width: 600px) {
  .hold-cell-display {
    margin-left: 6px;
  }
}

.hold-fieldset {
  width: auto;
  height: auto;
  border: 1px dashed var(--color-hold-display-border);
  border-radius: 6px;
}
@media (max-width: 600px) {
  .hold-fieldset {
    padding: 5.6px 8px 10px;
  }
}

.hold-legend {
  font-size: 14px;
}

.hold-cell-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
