.game {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  margin-top: 40px;
}

.button-rules {
  margin-top: 10px;
}