@import "Colors.css";

.header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  height: 48px;
  width: 100%;

  background-color: var(--color-header);
  border-bottom: 1px dotted var(--color-app-border);
  color: var(--color-header-text);
}

.header-title {
  padding: 0 15px;

  font-size: 24px;
  z-index: 1;
}
@media (max-width: 767px) {
  .header-title {
    font-size: 16px;
  }
}

.header-log-in {
  padding: 0 15px;

  font-size: 18px;
  z-index: 1;
  cursor: pointer;
}
@media (max-width: 767px) {
  .header-log-in {
    font-size: 14px;
  }
}

.header-log-in:hover {
  color: var(--color-header-highlight);
}

.header-welcome {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;

  padding: 0 15px;

  font-size: 18px;
  z-index: 1;
}
@media (max-width: 767px) {
  .header-welcome {
    font-size: 14px;
  }
}

.header-user-name {
  padding-right: 8px;
  cursor: pointer;
}

.header-user-name-span {
  color: var(--color-header-user-name);
}

.header-log-out {
  cursor: pointer;
}

.header-log-out:hover {
  color: var(--color-header-highlight);
}
