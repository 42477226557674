@import "Colors.css";

.cell {
  border: 1px solid black;

  cursor: pointer;

  background-color: var(--color-unoccupied-cell);
}

.cell-hover {
  background-color: var(--color-hovered-cell);
}

.cell-occupied {
  background-color: var(--color-occupied-cell);
}

.cell-loading {
  cursor: wait;
}

.cell-start-letter-circle {
  border-radius: 50%;
  border: 2px solid var(--color-start-letter-border);
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: var(--color-start-letter-background);
  color: var(--color-start-letter-text);
}
@media (max-width: 600px) {
  .cell-start-letter-circle {
    width: 28px;
    height: 28px;
    line-height: 28px;
  }
}

.cell-expanded-square {
  border-radius: 6px;
  border: 2px solid var(--color-start-letter-border);
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: var(--color-expanded-cell);
  font-size: 22px;
}
@media (max-width: 600px) {
  .cell-expanded-square {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 16px;
  }
}

.row-start {
  border-right-color: var(--color-occupied-cell);
}

.row-middle {
  border-left-color: var(--color-occupied-cell);
  border-right-color: var(--color-occupied-cell);
}

.row-end {
  border-left-color: var(--color-occupied-cell);
}

.column-start {
  border-bottom-color: var(--color-occupied-cell);
}

.column-middle {
  border-top-color: var(--color-occupied-cell);
  border-bottom-color: var(--color-occupied-cell);
}

.column-end {
  border-top-color: var(--color-occupied-cell);
}
