@import "./styles/Colors.css";

.app {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  font-family: 'Rubik', sans-serif;

  background-color: var(--color-background);
}

.app-main {
  width: 100%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
}

.app-main-left-panel {
  width: 25%;
  min-height: 669px;
}
@media (max-width: 1049px) {
  .app-main-left-panel {
    display: none;
  }
}

.app-main-center-panel {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  width: 50%;
  padding-bottom: 72px;
  border-left: 1px dotted var(--color-app-border);
  border-right: 1px dotted var(--color-app-border);
  min-height: calc(100vh - 170px);
}
@media (max-width: 1049px) {
  .app-main-center-panel {
    width: 65%;
  }
}
@media (max-width: 767px) {
  .app-main-center-panel {
    width: 100%;
  }
}

.app-main-right-panel {
  width: 25%;
  min-height: 669px;
}
@media (max-width: 1049px) {
  .app-main-right-panel {
    width: 35%;
  }
}
@media (max-width: 767px) {
  .app-main-right-panel {
    display: none;
  }
}

.app-scoreboard-note {
  font-size: 12px;
  text-align: center;
  width: 80%;
  align-self: center;
  margin: auto;
}

body {
  background-color: var(--color-background);
}

button {
  cursor: pointer;
  background-color: var(--color-button);
  border: 1px solid var(--color-button-border);
  font-size: 16px;
  color: var(--color-button-text);
  padding: 10px 16px;
  border-radius: 6px;
}
button:hover {
  background: var(--color-button-hover);
}

label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
input[type=radio] {
  display: none;
}
.radio-span {
  display: inline-block;

  height: 12px;
  width: 12px;

  border: 3px solid var(--color-radio-border);
  border-radius: 50%;

  margin-right: 6px;
}
input[type=radio]:checked + span {
  background-color: var(--color-radio-checked);
}
input[type=radio]:disabled + span {
  background-color: var(--color-radio-border);
  cursor: not-allowed;
}

input[type=checkbox] {
  display: none;
}
.checkbox-span {
  display: inline-block;

  height: 12px;
  width: 12px;

  border: 3px solid var(--color-checkbox-border);
  border-radius: 4px;

  margin-right: 6px;
}
input[type=checkbox]:checked + span {
  background-color: var(--color-checkbox-checked);
}
input[type=checkbox]:disabled + span {
  background-color: var(--color-checkbox-border);
  cursor: not-allowed;
}
