.square {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 52px;
  height: 52px;

  font-size: 30px;
}
@media (max-width: 600px) {
  .square {
    width: 36px;
    height: 36px;
    font-size: 22px;
  }
}
