.scoreboard {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  border: 1px solid var(--color-scoreboard-border);
  border-radius: 6px;
  padding: 10px 10px 0;
  margin: 20px 40px;

  font-size: 16px;
  background-color: var(--color-scoreboard);
}
@media (max-width: 767px) {
  .scoreboard {
    margin: 0 auto;
    width: 240px;
  }
}

.scoreboard-title-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 10px;
  width: 100%;

  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 1199px) {
  .scoreboard-title-bar {
    font-size: 14px;
  }
}

.scoreboard-arrow {
  cursor: pointer;
}

.scoreboard-arrow-left {
  padding-left: 20px;
}

.scoreboard-arrow-right {
  padding-right: 20px;
}

.scoreboard-footer {
  border-top: 1px dotted var(--color-scoreboard-border);
  width: 100%;
  text-align: center;
  padding: 8px 0;
  margin: 8px 0 0;
  font-size: 12px;
}
