.hold-cell {
  border: 2px solid var(--color-unselected-tile-border);
  border-radius: 6px;
  margin: 0 6px;

  font-size: 32px;

  cursor: pointer;

  background-color: var(--color-unselected-tile);
}
@media (max-width: 600px) {
  .hold-cell {
    font-size: 22px;
    margin: 0 2px;
  }
}
