@import "Colors.css";

.scoreboard-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 5px 0;

  border-radius: 6px;

  cursor: default;
}
@media (max-width: 1199px) {
  .scoreboard-row {
    font-size: 12px;
  }
}

.scoreboard-row-odd {
  background-color: var(--color-scoreboard-row-odd);
}

.scoreboard-row-even {
  background-color: var(--color-scoreboard-row-even);
}

.scoreboard-row-rank {
  padding-left: 20px;
  width: 20px;

  text-align: center;
}

.scoreboard-row-identity {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.scoreboard-row-star-gold,
.scoreboard-row-trophy-gold {
  color: var(--color-accolade-gold);
}

.scoreboard-row-star-silver,
.scoreboard-row-trophy-silver {
  color: var(--color-accolade-silver);
}

.scoreboard-row-star-bronze,
.scoreboard-row-trophy-bronze {
  color: var(--color-accolade-bronze);
}

.scoreboard-row-name {
  padding-left: 4px;
}

.scoreboard-row-score {
  padding-right: 20px;
  width: 20px;

  text-align: center;
  font-weight: bold;
}

.scoreboard-row-clickable {
  cursor: pointer;
}

.scoreboard-row-hover {
  background-color: var(--color-scoreboard-row-hover);
  color: var(--color-scoreboard-row-hover-text);
}

.scoreboard-row-score-history {
  width: 100%;
  text-align: center;
}
