:root {
  --color-blue: #4c76ea;
  --color-dark-blue: #4c21ea;
  --color-orange: #ff9f00;
  --color-super-light-grey: #f9f9f9;
  --color-light-grey: #e6e6e6;
  --color-medium-grey: #cccccc;
  --color-dark-grey: #b3b3b3;
  --color-super-dark-grey: #333333;
  --color-gold: #ffd700;
  --color-silver: #c0c0c0;
  --color-bronze: #cd7f32;
  --color-light-green: #90ee90;

  --color-background: var(--color-super-light-grey);
  --color-header: var(--color-blue);
  --color-header-text: white;
  --color-footer: var(--color-orange);
  --color-footer-link: white;
  --color-selected-tile: var(--color-medium-grey);
  --color-selected-tile-border: var(--color-blue);
  --color-unselected-tile: white;
  --color-unselected-tile-border: black;
  --color-unpickable-tile: var(--color-orange);
  --color-unpickable-tile-border: black;
  --color-occupied-cell: var(--color-dark-grey);
  --color-unoccupied-cell: white;
  --color-hovered-cell: var(--color-light-grey);
  --color-app-border: var(--color-super-dark-grey);
  --color-button: var(--color-blue);
  --color-button-border: var(--color-super-dark-grey);
  --color-button-hover: var(--color-dark-blue);
  --color-button-text: white;
  --color-scoreboard: white;
  --color-scoreboard-border: var(--color-medium-grey);
  --color-scoreboard-row-odd: white;
  --color-scoreboard-row-even: var(--color-light-grey);
  --color-scoreboard-row-hover: var(--color-blue);
  --color-scoreboard-row-hover-text: white;
  --color-schedule-background: white;
  --color-schedule-row-odd: white;
  --color-schedule-row-even: var(--color-light-grey);
  --color-radio-border: var(--color-medium-grey);
  --color-radio-checked: var(--color-blue);
  --color-checkbox-border: var(--color-medium-grey);
  --color-checkbox-checked: var(--color-blue);
  --color-hold-display-border: var(--color-dark-grey);
  --color-preview-display-border: var(--color-dark-grey);
  --color-preview-tile-border: var(--color-medium-grey);
  --color-preview-tile: white;
  --color-preview-tile-letter: var(--color-medium-grey);
  --color-preview-display-text: var(--color-dark-grey);
  --color-start-letter-background: var(--color-super-dark-grey);
  --color-start-letter-border: black;
  --color-start-letter-text: white;
  --color-modal-background: white;
  --color-header-user-name: var(--color-orange);
  --color-accolade-gold: var(--color-gold);
  --color-accolade-silver: var(--color-silver);
  --color-accolade-bronze: var(--color-bronze);
  --color-header-highlight: var(--color-orange);
  --color-daily-score: var(--color-blue);
  --color-email-address: var(--color-blue);
  --color-expanded-cell: var(--color-light-green)
}
