@import "Colors.css";

.control-panel {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  margin-top: 20px;
  width: 100%;
}

.control-panel-row {
  margin: 8px 0;
}
.control-panel-fieldset {
  width: 240px;
  padding-bottom: 16px;
  border: 1px solid black;
  border-radius: 6px;
}
.control-panel-fieldset-options {
  height: auto;
}
.control-panel-legend {
  padding: 0 6px;
  border-radius: 4px;
  font-size: 16px;
  background-color: var(--color-background);
}
.control-panel-label {
  font-size: 14px;
}
.control-panel-fieldset-inner {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;

  padding-top: 5px;
  width: 100%;
}
.control-panel-fieldset-inner-options {
  flex-flow: column nowrap;
}
.control-panel-label-option {
  width: 70%;
  padding-bottom: 4px;
}

.name-input {
  font-size: 14px;
  border: none;
  border-bottom: 1px dashed black;
  padding-bottom: 6px;
  background-color: var(--color-background);
  width: 70%;
  text-align: center;
}
.name-input:focus {
  outline: none;
}

.button-row {
  margin-top: 12px;
}

.button-continue {
  margin-top: 14px;
}

.control-panel-played {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  line-height: 24px;
  padding: 0 40px;
  text-align: center;
}

.control-panel-daily-board {
  padding: 20px 0 6px;
}

.daily-score {
  color: var(--color-daily-score);
  font-weight: bold;
}

.seven-day-scores {
  color: var(--color-daily-score);
  font-weight: bold;
}

.email-address {
  color: var(--color-email-address);
  font-weight: bold;
}

.error-message {
  font-size: 14px;
  margin: 10px 40px;
  text-align: center;
  white-space: pre-wrap;
  line-height: 20px;
}

.control-panel-note {
  text-align: center;
  width: 80%;
  font-size: 14px;
  line-height: 20px;
  padding-top: 15px;
}
@media (max-width: 767px) {
  .control-panel-note {
    display: none;
  }
}

.control-panel-logged-in-user-name {
  font-size: 20px;
}

.span-bold {
  font-weight: bold;
}

.span-italic {
  font-style: italic;
}

@media (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}

@media (max-width: 767px) {
  .no-mobile {
    display: none;
  }
}

@media (min-width: 1050px) {
  .mobile-and-tablet-only {
    display: none;
  }
}

.control-panel-seven-days {
  padding: 12px 0;
  text-align: center;
}

.horizontal-rule {
  background-color: black;
  height: 1px;
  margin: 0 auto 20px;
  width: 100px;
}

.control-panel-buttons {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 10px;
}

.button-control-panel {
  margin-bottom: 10px;
}
