.login-modal {
  position: fixed;
  background: var(--color-modal-background);
  border: 2px solid var(--color-app-border);
  border-radius: 6px;
  padding: 20px;
  font-size: 14px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 240px;
  width: 360px;
  max-width: 80vw;
}

.login-form {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.login-modal-register {
  height: 320px;
}

.login-button {
  margin-top: 16px;
  font-size: 14px;
  padding: 8px 12px;
}

.input {
  font-size: 14px;
  border: none;
  border-bottom: 1px dashed black;
  padding-bottom: 6px;
  margin-left: 10px;
  margin-bottom: 12px;
  background-color: var(--color-modal-background);
  width: 200px;
  text-align: center;
}
.input:focus {
  outline: none;
}

label {
  text-align: center;
}

.label-name {
  margin-top: 10px;
}

.label-registering {
  margin-top: 16px;
  margin-bottom: 20px;
}

.label-email {
  margin-bottom: 10px;
}

.login-modal-error {
  padding-top: 20px;
  text-align: center;
}

.login-modal-buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.login-modal-button {
  margin: 0 6px;
}
