.search-bar {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  padding-top: 16px;
}

.search-bar-fieldset {
  height: 60px;
  border: 1px solid black;
  border-radius: 6px;
}
.search-bar-fieldset-inner {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;

  padding-top: 0;
  width: 100%;
}
.search-bar-input {
  font-size: 14px;
  border: none;
  border-bottom: 1px dashed black;
  padding-bottom: 6px;
  background-color: var(--color-background);
  width: 40%;
  text-align: center;
  text-transform: uppercase;
}
.search-bar-input:focus {
  outline: none;
}

.search-bar-message {
  padding-top: 8px;
}
