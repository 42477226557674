.schedule-modal {
  position: fixed;
  background: var(--color-modal-background);
  border: 2px solid var(--color-app-border);
  border-radius: 6px;
  padding: 20px;
  font-size: 14px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 420px;
  width: 640px;
}

.schedule-modal-header {
  padding-bottom: 10px;
}

.schedule-modal-table {
  text-align: center;
  border-collapse: collapse;
}

.schedule-modal-row {
  border-radius: 4px;
  height: 40px;
}

.schedule-modal-row-even {
  background-color: var(--color-schedule-row-even);
}

.schedule-modal-row-odd {
  background-color: var(--color-schedule-row-odd);
}

.schedule-modal-cell {
  padding: 5px 15px;
}

.schedule-modal-table-header-row {
  height: 40px;
}

.schedule-modal-table-header {
  padding: 5px 10px;
  width: 40px;
}

.schedule-modal-close {
  margin-top: 16px;
  font-size: 14px;
  padding: 8px 12px;
}
